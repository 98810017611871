.react-datetime-picker {
  display: inline-flex;
  position: relative;
  align-items: center;
  width: 100%;
}
.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-datetime-picker--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 10px;
  box-sizing: border-box;
  transition: 300ms border-color;
}

.react-datetime-picker--open .react-datetime-picker__wrapper {
  border-color: #037bff;
}

[data-invalid="true"] .react-datetime-picker__wrapper {
  border-color: #F10D00;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  display: flex;
  align-items: baseline;
}
.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  outline: none;
}
.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datetime-picker__inputGroup__input:invalid {
  color: #F10D00;
}

.react-datetime-picker__inputGroup__input:disabled {
  cursor: not-allowed;
  color: #000;
}
.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-datetime-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}
.react-datetime-picker__button {
  border: 0;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
}
.react-datetime-picker__button:enabled {
  cursor: pointer;
}

.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: #6d6d6d;
}

.react-datetime-picker__button svg {
  transition: 300ms fill, 300ms stroke;
}

.react-datetime-picker__calendar-button svg {
  display: inherit;
  fill: #a8b8ca;
  width: 20px;
  margin-top: -1px;
}


.react-datetime-picker--enabled .react-datetime-picker__calendar-button:hover svg,
.react-datetime-picker--open .react-datetime-picker__calendar-button svg {
  fill: #037bff;
}

.react-datetime-picker--disabled .react-datetime-picker__button {
  cursor: not-allowed;
}

.react-datetime-picker__clear-button svg {
  stroke: #a8b8ca;
  width: 16px;
}

.react-datetime-picker--disabled .react-datetime-picker__clear-button {
  opacity: 0;
}

.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
  stroke: #037bff;
  fill: #037bff;
}

.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-datetime-picker__calendar--closed,
.react-datetime-picker__clock--closed {
  display: none;
}
.react-datetime-picker__calendar {
  width: 320px;
  max-width: 100vw;
}

.react-datetime-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
}

.react-calendar {
  margin-top: 8px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.24);
  border: none;
  border-radius: 8px;
  width: 320px;
  padding: 5px;
}

.react-calendar button {
  border-radius: 4px;
}

.react-calendar__tile--active {
  background-color:  #037bff;
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile--now {
  background-color: #ff9900;
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #ff9900;
}


.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation {
  margin-bottom: 8px;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next-button,
.react-calendar__navigation__next2-button {
  font-size: 20px;
}
